import React from "react";
import { graphql, Link as GatsbyLink } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Helmet from "react-helmet";
import styled from "styled-components";
import {
  Tile,
  TilesContainer,
  TitleContainer,
  Thumbnail,
} from "../components/foundations";
export default function Link({ data }) {
  const url = "https://zholobak.com";

  // 👇 graphql
  const itemData = data.item;

  // 👇 context
  const items = data.product.items.filter((i) => i.slug !== itemData.slug);

  const publishedAtFormat =
    itemData.date &&
    Intl.DateTimeFormat("ru", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(itemData.date));

  const PlayerLazy = React.lazy(() => import("../components/player"));
  const isSSR = typeof window === "undefined";

  const hasVimeoVideo = itemData.vimeoVideo.video360p || itemData.vimeoVideo.video720p || itemData.vimeoVideo.video1080p || itemData.vimeoVideo.video2160p;

  return (
    <>
      <Helmet htmlAttributes={{ lang: "ru" }}>
        <title>{itemData.title}</title>
        <meta name="description" content={itemData.description} />
        <meta name="author" content="Надежда Жолобак" />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={itemData.date} />
        <meta property="article:modified_time" content={itemData.updatedAt} />
        <meta property="og:url" content={url + "/" + itemData.slug + "/"} />
        <meta property="og:title" content={itemData.title} />
        <meta property="og:description" content={itemData.description} />
        <meta property="og:image" content={url + "/og.png"} />
        <meta property="og:image:alt" content={itemData.title} />
      </Helmet>
      <Header>
        <address>
          <Author>
            <StaticImage
              src="../images/icon.jpg"
              alt="Надежда Жолобак"
              placeholder="blurred"
              layout="fixed"
              width={40}
              height={40}
              style={{ display: "inline-block" }}
              imgStyle={{ borderRadius: 8 }}
            />
            Надежда Жолобак
          </Author>
        </address>
        {!isSSR && hasVimeoVideo && (
          <React.Suspense fallback={<div />}>
            <PlayerLazy
              files={{
                p360: itemData.vimeoVideo.video360p,
                p720: itemData.vimeoVideo.video720p,
                p1080: itemData.vimeoVideo.video1080p,
                p2160: itemData.vimeoVideo.video2160p,
              }}
              poster={itemData.vimeoVideo.posterFrame.url}
              title={itemData.title}
            />
          </React.Suspense>
        )}
        {!hasVimeoVideo && itemData.link && (
          <div class="plyr__video-embed" id="yt-player">
            <iframe
              src={itemData.link}
              allowfullscreen
              allowtransparency
              allow="autoplay"
              title={itemData.title}
            />
          </div>
        )}
        <h1>{itemData.title}</h1>
        <time pubdate="true" dateTime={itemData.date} title={publishedAtFormat}>
          {publishedAtFormat}
        </time>
        <p>{itemData.description}</p>
      </Header>
      <main>
        {itemData.content && (
          <div dangerouslySetInnerHTML={{ __html: itemData.content.html }} />
        )}
      </main>
      <aside>
        <h2>Другие интервью Надежды Жолобак:</h2>
        <TilesContainer>
          {items.map((i) => (
            <Tile to={`/${i.slug}/`} key={i.slug}>
              <TitleContainer>
                {i.vimeoVideo?.posterFrame && (
                  <Thumbnail src={i.vimeoVideo.posterFrame.url} />
                )}
                <h3>{i.title}</h3>
              </TitleContainer>
              {i.ogDate && (
                <time pubdate dateTime={i.ogDate}>
                  {Intl.DateTimeFormat("ru", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(i.ogDate))}
                </time>
              )}
            </Tile>
          ))}
        </TilesContainer>
      </aside>
    </>
  );
}
export const query = graphql`
  query ($slug: String!) {
    item: graphCmsItem(slug: { eq: $slug }) {
      link
      slug
      vimeoVideo {
        video1080p
        video360p
        video720p
        posterFrame {
          url
        }
      }
      title
      description
      date
      content {
        html
      }
      updatedAt
    }
    product: graphCmsProduct(title: { eq: "Надежда Жолобак" }) {
      items {
        slug
        category
        title
        description
        date
        vimeoVideo {
          posterFrame { url }
        }
      }
    }
  }
`;

const Header = styled.header`
  h1 {
    margin-bottom: 0.2em;
  }
  p {
    font-size: 1.2em;
  }
`;

const Author = styled(GatsbyLink).attrs(() => ({
  to: "/",
}))`
  display: flex;
  align-items: center;
  gap: 24px;
`;
